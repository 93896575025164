import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import {UserService} from './services/user.service';
import {UserEntity} from './entity/UserEntity';
import {User} from '../models/User';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    public username: string;
    public password: string;

    public statusText: string;

    constructor(private http: HttpClient, private userService: UserService, private router: Router) {
    }

    ngOnInit() {
        const usernameInput = document.getElementById('username-input');
        usernameInput.addEventListener('keyup', (event) => {
            // Number 13 is the "Enter" key on the keyboard
            if (event.keyCode === 13) {
                event.preventDefault();
                document.getElementById('login').click();
            }
        });

        const passwordInput = document.getElementById('password-input');
        passwordInput.addEventListener('keyup', (event) => {
            // Number 13 is the "Enter" key on the keyboard
            if (event.keyCode === 13) {
                event.preventDefault();
                document.getElementById('login').click();
            }
        });
    }

    login() {
        this.http.post(environment._proxy + '/user/login', {
            username: this.username,
            password: this.password
        } as User, {responseType: 'text'})
            .subscribe((response: string) => {
                if (response !== '') {
                    localStorage.setItem('token', response.toString());
                    this.userService.username = this.username;
                    this.userService.password = this.password;
                    this.requestUserEntity();
                } else {
                    this.statusText = 'Die eingegebenen Benutzerdaten sind falsch. Versuchen Sie es erneut.';
                    if (localStorage.getItem('token')) {
                        localStorage.removeItem('token');
                    }
                }
            });
    }

    requestUserEntity() {
        this.http.get(environment._proxy + '/user/get',
            {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}}).subscribe((userEntity: UserEntity) => {
            this.userService.userEntity = userEntity;
            this.router.navigateByUrl('user-panel');
        });
    }

    resetPlaceholders() {
        (document.getElementById('username') as HTMLInputElement).placeholder = 'Benutzername';
        (document.getElementById('password') as HTMLInputElement).placeholder = 'Passwort';
    }
}
