<section id="user-panel-create-news-container" fxLayout="row" fxLayoutGap="5rem">
    <div id="input-section" fxLayout="column" fxLayoutGap="2rem">
        <div fxLayout="column" fxLayoutGap=".5rem">
            <h1 class="title">News erstellen</h1>
            <p class="context">Pflichtfelder: *</p>
        </div>
        <div id="create-news-text-input-container" fxLayout="column" fxLayoutGap="1.5rem">
            <p class="context" style="font-weight: 600;">Titel *</p>
            <input class="create-news-input" [(ngModel)]="newsTitle">
            <p class="context" style="font-weight: 600;">Kategorie</p>
            <select class="create-news-input create-news-select" [(ngModel)]="selectedCategory">
                <option [ngValue]="undefined" selected>Keine Kategorie</option>
                <option [ngValue]="">Neue Kategorie erstellen</option>
                <option *ngFor="let c of categories" [ngValue]="c.name">{{c.name}}</option>
            </select>
            <div *ngIf="selectedCategory === 'Neue Kategorie erstellen'"
                 fxLayout="column"
                 fxLayoutGap="1rem">
                <p class="context" style="font-weight: 600;">Name der neuen Kategorie *</p>
                <input class="create-news-input" [(ngModel)]="newCategoryName">
            </div>
            <p class="context" style="font-weight: 600;">Nachricht *</p>
            <textarea id="create-news-textarea" [(ngModel)]="newsText"></textarea>

            <p class="context" style="font-weight: 600;">Links (optional)</p>
            <div *ngFor="let x of linkArray; let i = index;">
                <input [(ngModel)]="linkArray[i].name">
            </div>
            <div fxLayout="row" style="max-height: 2.5rem" fxLayoutGap=".5rem">
                <input class="create-news-input" [(ngModel)]="newLink">
                <button class="submit" (click)="addLink()">Add link</button>
            </div>


            <!--p class="context" style="font-weight: 600;">Thumbnail hinzufügen (optional)</p>
            <label for="thumbnail" class="file-upload-label" fxLayoutAlign="center center">
    Thumbnail hinzufügen
</label-->
            <p class="context" style="font-weight: 600;">Thumbnail hinzufügen (optional)</p>
<!--            <label  class="file-upload-label" fxLayoutAlign="center center">-->
<!--                Thumbnail hinzufügen-->
<!--            </label>-->
<!--            <input id="thumbnail" type="file" accept="image/x-png,image/gif,image/jpeg"-->
<!--                   style="display: none; cursor: pointer;"-->
<!--                   (change)="onThumbnailChanged()">-->
            <button (click)="onThumbnailChanged()" class="submit" style="width: auto !important;" fxLayoutAlign="center center">Thumbnail hinzufügen</button>
            <span *ngIf="this.sizeExceeded" style="color: crimson;">{{this.imageSizeExceededError}}</span>
<!--            <p class="context" style="font-weight: 600;">Bilder hinzufügen (optional)</p>-->
<!--            <label for="file" class="file-upload-label" fxLayoutAlign="center center">-->
<!--                Bild hinzufügen-->
<!--            </label>-->
<!--            <input id="file" type="file" accept="image/x-png,image/gif,image/jpeg"-->
<!--                   style="cursor: pointer; display: none;"-->
<!--                   (change)="onFileChanged($event)"-->
<!--                   multiple>-->
            <!--div>
                <button (click)="compressFile(this.thumbnail[0])">Compress thumbnail</button>
                <img *ngIf="imgResultAfterCompress" [src]="imgResultAfterCompress" alt="">
            </div-->
        </div>
        <button class="submit" [disabled]="processingPicture" (click)="createNews()" name="actionBtn">{{actionBtn}}</button>
    </div>
    <div id="overview-section" fxLayout="column" fxLayoutAlign="start center"
         *ngIf="newsTitle !== '' || newsText !== ''">
        <div class="bdm-news" fxLayout="column" fxLayoutAlign="start center"
             fxLayoutGap=".5rem">
            <p class="news-company-name"> {{newsTitle}}</p>
<!--            <p class="news-company-name"> {{this.userInfo.companyName}}</p>-->
            <!--      <div class="news-seperator"></div>-->
            <img class="news-thumbnail" src="{{this.imgResultAfterCompress}}">
<!--            <img class="news-company-logo" src="{{files[0]}}">-->
            <pre class="news-message">{{newsText}}</pre>
            <!--      <div class="news-seperator"></div>-->
        </div>
    </div>
</section>
