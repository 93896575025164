import {Component, OnInit} from '@angular/core';
import {UserPanelNavigationService} from '../services/user-panel-navigation.service';

@Component({
    selector: 'app-user-panel-navigation',
    templateUrl: './user-panel-navigation.component.html',
    styleUrls: ['./user-panel-navigation.component.css']
})
export class UserPanelNavigationComponent implements OnInit {

    constructor(public userPanelNavigationService: UserPanelNavigationService) {
    }

    ngOnInit() {
    }

}
