import {Component, OnInit} from '@angular/core';
import {NewsService} from '../services/news.service';

export function formatDate(date: string): string {
    const d = new Date(date);
    return  ('0' + d.getDate()).slice(-2) + '.'
        + ('0' + (d.getMonth() + 1)).slice(-2) + '.'
        + d.getFullYear();
}

@Component({
    selector: 'app-bdm-news-overview',
    templateUrl: './bdm-news-overview.component.html',
    styleUrls: ['./bdm-news-overview.component.css']
})
export class BdmNewsOverviewComponent implements OnInit {

    constructor(public newsService: NewsService) {
    }

    ngOnInit() {
    }
    formatDate(date: string): string {
        return formatDate(date);
    }
}
