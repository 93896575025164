<section id="interface-navigation-container" fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap=".5rem">
    <div class="navigation-category" fxLayoutAlign="center center"
         [ngClass]="{active: userPanelNavigationService.selectedUserPanelNavigation === userPanelNavigationService.getSelectedUserPanelNavigationInstance().STARTSEITE}"
         (click)="userPanelNavigationService.selectNavigation(userPanelNavigationService.getSelectedUserPanelNavigationInstance().STARTSEITE)">Startseite</div>
    <div class="navigation-category" fxLayoutAlign="center center"
         [ngClass]="{active: userPanelNavigationService.selectedUserPanelNavigation === userPanelNavigationService.getSelectedUserPanelNavigationInstance().ERSTELLEN}"
         (click)="userPanelNavigationService.selectNavigation(userPanelNavigationService.getSelectedUserPanelNavigationInstance().ERSTELLEN)">News erstellen</div>
    <div class="navigation-category" fxLayoutAlign="center center"
         [ngClass]="{active: userPanelNavigationService.selectedUserPanelNavigation === userPanelNavigationService.getSelectedUserPanelNavigationInstance().VERWALTEN}"
         (click)="userPanelNavigationService.selectNavigation(userPanelNavigationService.getSelectedUserPanelNavigationInstance().VERWALTEN)">News verwalten</div>
    <div class="navigation-category" fxLayoutAlign="center center"
         [ngClass]="{active: userPanelNavigationService.selectedUserPanelNavigation === userPanelNavigationService.getSelectedUserPanelNavigationInstance().IMPRESSUM}"
         (click)="userPanelNavigationService.selectNavigation(userPanelNavigationService.getSelectedUserPanelNavigationInstance().IMPRESSUM)">Impressum & Datenschutzerklärung</div>
</section>
