import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {UserEntity} from '../entity/UserEntity';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private _username: string;
    private _password: string;
    private _userEntity: UserEntity;

    constructor(private router: Router, private http: HttpClient) {

    }

    logout() {
        this.router.navigateByUrl('login');
        this.username = undefined, this.password = undefined, this.userEntity = undefined;
    }

    checkToken() {
        return this.http.get(environment._proxy + '/user/get', {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}});
    }

    get username(): string {
        return this._username;
    }

    set username(value: string) {
        this._username = value;
    }

    get password(): string {
        return this._password;
    }

    set password(value: string) {
        this._password = value;
    }

    get userEntity(): UserEntity {
        return this._userEntity;
    }

    set userEntity(value: UserEntity) {
        this._userEntity = value;
    }
}
