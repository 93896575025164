import {Component, OnInit} from '@angular/core';
import {NewsService} from '../../services/news.service';
import {UserService} from '../../login/services/user.service';
import {LinkDTO} from '../../models/LinkDTO';
import {CategoryDTO} from '../../models/CategoryDTO';
import {NgxImageCompressService} from 'ngx-image-compress';
import {UserPanelNavigationService} from '../services/user-panel-navigation.service';
import {News} from '../../models/News';
import {ImageDTO} from '../../models/ImageDTO';

@Component({
    selector: 'app-user-panel-create-news',
    templateUrl: './user-panel-create-news.component.html',
    styleUrls: ['./user-panel-create-news.component.css']
})
export class UserPanelCreateNewsComponent implements OnInit {

    public newsText = '';
    public newsTitle = '';
    public newLink: string;
    public linkArray: LinkDTO[] = [];
    public files: ImageDTO[] = [];
    public thumbnail: ImageDTO = {} as ImageDTO;
    public processingPicture = false;
    public thumbnailImage: string;
    public imgResultAfterCompress: string;
    public imgResultBeforeCompress: string;

    public imageSizeExceededError = 'Die maximale Dateigröße beträgt 20 MB.';

    public actionBtn = 'Erstellen';

    public selectedCategory;
    public categories: CategoryDTO[];

    public newCategoryName: string;
    public sizeExceeded = false;

    constructor(public userService: UserService,
                public newsService: NewsService,
                private imageCompress: NgxImageCompressService,
                private navigationService: UserPanelNavigationService) {
    }

    addLink() {
        this.linkArray.push(new LinkDTO(this.newLink));
        this.newLink = '';
    }

    ngOnInit() {
        this.newsService.getCategories(this.userService.username).toPromise().then((response) => {
            this.categories = response as CategoryDTO[];
            this.actionBtn = 'Erstellen';
            if (this.newsService.newsInWork !== undefined) {
                this.updateCurrentViewData();
            }
        });
    }

    updateCurrentViewData() {
        this.newsText = this.newsService.newsInWork.message;
        this.linkArray = this.newsService.newsInWork.links;
        this.newsTitle = this.newsService.newsInWork.title;
        let name = this.newsService.newsInWork.user.username;
        if (name === 'skilladmin') {
            name = 'bdmadmin';
        }
        // this.http.get(environment._proxy_bdm_secured + '/user/getUserInformationByUsername?username=' + name)
        //     .toPromise()
        //     .then((receivedUserInformation: UserInformation) => {
        //         this.userInfo = receivedUserInformation;
        //     });
        this.updateCurrentFiles(this.userService.username);
        this.actionBtn = 'Update';
        if (this.newsService.newsInWork.category) {
            this.selectedCategory = this.newsService.newsInWork.category.name;
        }
    }

    updateCurrentFiles(username: string) {
        this.newsService.newsInWork.images.forEach(
            (imageDTO) => {
                this.newsService.getImage(imageDTO.id).subscribe(
                    response => {
                        const image = {
                            id: imageDTO.id,
                            newsId: this.newsService.newsInWork.id,
                            file: new File([new Uint8Array(response as ArrayBuffer)], 'filename', {type: 'png'}),
                            thumbnail: imageDTO.thumbnail
                        };
                        if (image.thumbnail) {
                            this.thumbnail = image;
                            this.imgResultAfterCompress = 'data:image/jpeg;base64,' + this.arrayBufferToBase64(response as ArrayBuffer);
                        } else {
                            this.files.push(image);
                        }
                        // the images are saved locally into this.files and this.thumbnail.
                        // To update it to the input we have to create a DataTransfer object,
                        // add these files there and with DOM manipulation update the input data.
                        // The image adding part should be reviewed.
                    });
            });
    }

    arrayBufferToBase64(buffer: ArrayBuffer) {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    // keep in mind there is a size limit. Take that in consideration.
    // The same function can be used for updating but instead of news ID the function takes in the image ID.
    appendImage(id: number, file: any, isThumbnail: boolean) {
        const uploadImageData = new FormData();
        uploadImageData.append('file', file);
        uploadImageData.append('id', id.toString());
        // JSON.stringify({ thumbnail: isThumbnail }) -> for the future update with Part type.
        uploadImageData.append('thumbnail', isThumbnail.toString());
        this.newsService.appendImage(uploadImageData).subscribe(
            response => {
                console.log(response);
                this.navigationService.selectNavigation(this.navigationService.getSelectedUserPanelNavigationInstance().VERWALTEN);
            });
    }

    base64ToArrayBuffer(base64) {
        const binaryString = atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }

    updateImage(id: number, file: any, isThumbnail: boolean) {
        const uploadImageData = new FormData();
        uploadImageData.append('file', file);
        uploadImageData.append('imageId', id.toString());
        // JSON.stringify({ thumbnail: isThumbnail }) -> for the future update with Part type.
        uploadImageData.append('thumbnail', isThumbnail.toString());
        this.newsService.updateImage(uploadImageData).subscribe(
            response => {
                console.log(response);
            });
    }

    onFileChanged(event: any) {
        this.files = event.target.files;
    }

    onThumbnailChanged(): void {
        this.processingPicture = true;
        this.imageCompress.uploadFile().then(({image, orientation}): void => {
            console.warn('Size in bytes was:', this.imageCompress.byteCount(image));
            if (this.imageCompress.byteCount(image) > 20 * 1000000) {
                this.sizeExceeded = true;
                return;
            }
            this.imageCompress.compressFile(image, orientation, 55, 75).then(
                (result): void => {
                    this.sizeExceeded = false;
                    this.processingPicture = false;
                    this.imgResultAfterCompress = result;
                    this.thumbnail.file = this.dataURLtoFile(result, 'thumbnail');
                    console.warn('Size in bytes is now:', this.imageCompress.byteCount(this.imgResultAfterCompress));
                }
            );
        });
    }

    dataURLtoFile(dataURL: string, fileName: string): File {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)?.[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], fileName, {type: mime});
    }

    createNews() {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();

        let category = this.newCategoryName;
        // review this so that the category is chosen correctly, this works but its ugly. REFACTOR AWAITING
        if (this.selectedCategory !== 'Neue Kategorie erstellen') {
            category = this.selectedCategory;
        }
        if (category === undefined) {
            category = null;
        }
        if (this.newsService.newsInWork !== undefined) {
            this.newsService.updateNews(this.newsService.newsInWork.id, this.newsService.newsInWork.author, this.newsText,
                dd + '.' + mm + '.' + yyyy, this.newsTitle,
                // REVIEW PLEASE
                new CategoryDTO(category, this.userService.username), this.linkArray).subscribe(
                data => {
                    this.navigationService.selectNavigation(this.navigationService.getSelectedUserPanelNavigationInstance().VERWALTEN);
                    for (const file of this.files) {
                        if (file.id) {
                            this.updateImage(file.id, file.file, false);
                        } else {
                            this.appendImage((data as News).id, file.file, false);
                        }
                    }
                    if (this.thumbnail.id) {
                        this.updateImage(this.thumbnail.id, this.thumbnail.file, true);
                    } else {
                        this.appendImage((data as News).id, this.thumbnail.file, true);
                    }
                }
            );
            this.newsService.newsInWork = undefined;
        } else {
            if (category !== null) {
                this.newsService.createNews(this.userService.username, this.newsText, dd + '.' + mm + '.' + yyyy, this.newsTitle,
                    new CategoryDTO(category, this.userService.username), this.linkArray).subscribe(
                    data => {
                        for (const file of this.files) {
                            this.appendImage((data as News).id, file, false);
                        }
                        if (this.thumbnail.file) {
                            this.appendImage((data as News).id, this.thumbnail.file, true);
                        } else {
                            this.navigationService.selectNavigation(
                                this.navigationService.getSelectedUserPanelNavigationInstance().VERWALTEN);
                        }
                    });
            } else {
                this.newsService.createNews(this.userService.username, this.newsText, dd + '.' + mm + '.' + yyyy, this.newsTitle,
                    null, this.linkArray).subscribe(
                    data => {
                        for (const file of this.files) {
                            this.appendImage((data as News).id, file, false);
                        }
                        if (this.thumbnail.file) {
                            this.appendImage((data as News).id, this.thumbnail.file, true);
                        } else {
                            this.navigationService.selectNavigation(
                                this.navigationService.getSelectedUserPanelNavigationInstance().VERWALTEN);
                        }
                    });
            }
        }
    }
}
