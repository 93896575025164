import {Injectable} from '@angular/core';
import {SelectedUserPanelNavigation} from '../enum/SelectedUserPanelNavigation';

@Injectable({
    providedIn: 'root'
})
export class UserPanelNavigationService {

    private _selectedUserPanelNavigation: SelectedUserPanelNavigation = SelectedUserPanelNavigation.STARTSEITE;

    constructor() {
    }

    selectNavigation(userPanelNavigationToSelect: SelectedUserPanelNavigation): void {
        this.selectedUserPanelNavigation = userPanelNavigationToSelect;
    }

    getSelectedUserPanelNavigationInstance(): typeof SelectedUserPanelNavigation {
        return SelectedUserPanelNavigation;
    }

    get selectedUserPanelNavigation(): SelectedUserPanelNavigation {
        return this._selectedUserPanelNavigation;
    }

    set selectedUserPanelNavigation(value: SelectedUserPanelNavigation) {
        this._selectedUserPanelNavigation = value;
    }
}
