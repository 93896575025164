import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NewsDTO} from '../models/NewsDTO';
import {CategoryDTO} from '../models/CategoryDTO';
import {LinkDTO} from '../models/LinkDTO';
import {Observable} from 'rxjs';
import {User} from '../models/User';

@Injectable({
    providedIn: 'root'
})
export class NewsService {

    public newsInWork: NewsDTO;
    private _allNews: NewsDTO[];

    constructor(private http: HttpClient) {
    }

    register(user: string, pswd: string) {
        return this.http.post(environment._proxy + '/user/register',
            {
                username: user, password: pswd
            });
    }

    login(user: string, pswd: string) {
        return this.http.post(environment._proxy + '/user/login',
            {
                username: user, password: pswd
            });
    }

    getCategories(user: string): Observable<any> {
        // return this.http.post(environment._proxy + '/category/get/all/auth',
        //     {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}});
        return this.http.post(environment._proxy + '/category/get/all/user', {username: user} as User,
            {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}});
    }

    createNews(author: string, message: string, date: string, title: string, category: CategoryDTO, links: LinkDTO[]) {
        const d = new Date(date);
        return this.http.post(environment._proxy + '/news/save', {
            author,
            message,
            d,
            title,
            category,
            links
        }, {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}});
    }

    updateNews(id: number, author: string, message: string, date: string, title: string, category: CategoryDTO, links: LinkDTO[]) {
        const d = new Date(date);
        return this.http.post(environment._proxy + '/news/save', {
            id,
            author,
            message,
            d,
            title,
            category,
            links
        }, {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}});
    }

    getNewsByAuthorAndCategoryName(author: string, categoryName: string): Observable<any> {
        return this.http.post(environment._proxy + '/news/get/user/category/',
            {name: categoryName, user: {username: author} as User} as CategoryDTO,
            {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}});
    }

    appendImage(data: FormData) {
        return this.http.post(environment._proxy + '/image/append', data,
            {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}});
    }

    getNewsByAuthor(author: string) {
        return this.http.post(environment._proxy + '/news/get/all/user', {username: author},
            {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}});
    }

    getNewsByAuthorWithoutCategory(author: string) {
        return this.http.post(environment._proxy + '/news/get/user/no/category', {username: author} as User,
            {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}});
    }

    updateImage(data: FormData) {
        return this.http.post(environment._proxy + '/image/update', data,
            {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}});
    }

    // updateNews(id: number, author: string, message: string, date: string, images: ImageDTO[], title: string) {
    //     return this.http.post(environment._proxy + '/update-news', new NewsDTO(author, message, date, images, title));
    // }

    getAllNews() {
        return this.http.post(environment._proxy + '/news/get/no/category',
            {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}});
    }

    getNewsByDate(tempDate: string) {
        return this.http.post(environment._proxy + '/news/get/date',
            tempDate,
            {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}}
        );
    }

    getImage(tempId: number) {
        const httpOptions = {
            responseType: 'arraybuffer' as 'json'
            // 'responseType'  : 'blob' as 'json'        //This also worked
        };

        return this.http.post(environment._proxy + '/image/get',
            {
                id: tempId
            }, httpOptions);
    }

    get allNews(): NewsDTO[] {
        return this._allNews;
    }

    set allNews(value: NewsDTO[]) {
        this._allNews = value;
    }
}
