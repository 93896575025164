import {Injectable} from '@angular/core';
import {SelectedLoginNavigation} from '../enum/SelectedLoginNavigation';

@Injectable({
    providedIn: 'root'
})
export class LoginNavigationService {

    private _selectedLoginNavigation: SelectedLoginNavigation = SelectedLoginNavigation.STARTSEITE;

    constructor() {
    }

    selectNavigation(loginNavigationToSelect: SelectedLoginNavigation): void {
        this.selectedLoginNavigation = loginNavigationToSelect;
    }

    getSelectedLoginNavigationInstance(): typeof SelectedLoginNavigation {
        return SelectedLoginNavigation;
    }

    get selectedLoginNavigation(): SelectedLoginNavigation {
        return this._selectedLoginNavigation;
    }

    set selectedLoginNavigation(value: SelectedLoginNavigation) {
        this._selectedLoginNavigation = value;
    }
}
