import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NewsDTO} from './models/NewsDTO';
import {environment} from '../environments/environment';
import {UserInformation} from './models/UserInformation';
import {NewsService} from './services/news.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    title = 'news-frontend';

    constructor(private http: HttpClient,
                private newsService: NewsService) {
    }

    ngOnInit() {
        // this.newsService.getAllNews()
        //     .toPromise()
        //     .then((news: NewsDTO[]) => {
        //         for (const x of news) {
        //             // x.userInformation = {companyName: x.user.username} as UserInformation;
        //             this.http.get(environment._proxy_bdm_secured + '/user/getUserInformationByUsername?username=' + x.author)
        //                 .toPromise()
        //                 .then((receivedUserInformation: UserInformation) => {
        //                     console.log(receivedUserInformation);
        //                     x.userInformation = receivedUserInformation;
        //                     this.newsService.allNews = news;
        //                 });
        //         }
        //     });
    }
}
