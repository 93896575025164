import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../login/services/user.service';
import {UserPanelNavigationService} from './services/user-panel-navigation.service';
import {UserEntity} from '../login/entity/UserEntity';

@Component({
    selector: 'app-user-panel',
    templateUrl: './user-panel.component.html',
    styleUrls: ['./user-panel.component.css']
})
export class UserPanelComponent implements OnInit {

    constructor(private router: Router,
                private userService: UserService,
                public userPanelNavigationService: UserPanelNavigationService) {
    }

    ngOnInit() {
        this.userService.checkToken().toPromise()
            .then((user: UserEntity) => {
                this.userService.userEntity = user;
                this.userService.username = user.username;
                if (this.userService.username === undefined) {
                    this.router.navigateByUrl('/startseite');
                }
            }).catch(() => {
                localStorage.removeItem('token');
                this.router.navigateByUrl('/login');
            });
    }

}
