<div id="login-container"
     fxLayout="column"
     fxLayoutAlign="center start"
     fxLayoutGap="2rem">
    <h1 class="title">Bitte geben Sie Ihre Benutzerdaten ein</h1>
    <div id="login-input-container" fxLayout="column" fxLayoutGap="1rem">
        <p class="context">Benutzername</p>
        <input [(ngModel)]="username" id="username-input" placeholder="">
        <p class="context">Passwort</p>
        <input type="password" [(ngModel)]="password" id="password-input" placeholder="">
        <button id="login" (click)="login()">Anmelden</button>
    </div>
    <p id="status">{{statusText}}</p>
</div>
