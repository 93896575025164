import {Component, OnInit} from '@angular/core';
import {LoginNavigationService} from './services/login-navigation.service';

@Component({
    selector: 'app-login-navigation',
    templateUrl: './login-navigation.component.html',
    styleUrls: ['./login-navigation.component.css']
})
export class LoginNavigationComponent implements OnInit {

    constructor(public loginNavigationService: LoginNavigationService) {
    }

    ngOnInit() {
    }

}
