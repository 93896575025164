<section id="user-panel-manage-news-container" fxLayout="column"
         fxLayoutAlign="start center"
         fxLayoutGap="3rem">
    <h1 class="title" style="padding-left: 2rem;">News verwalten</h1>
    <div class="news-outer-container" fxLayout="column">
        <h1 class="title" style="margin-left: 1rem; margin-top: 1rem; font-size: 28px;">Keine Kategorie</h1>
        <table>
            <tr>
                <th>Titel</th>
                <th>Erstellt am</th>
                <th></th>
                <th></th>
            </tr>
            <tr *ngFor="let news of newsWithoutCategory">
                <td style="font-weight: 500;">{{news?.title}}</td>
                <td>{{formatDate(news?.date)}}</td>
                <td><img (click)="rerouteToUpdate(news)" class="icon" src="../../../assets/edit.svg"></td>
                <td (click)="deleteNews(news?.id)"><img class="icon" src="../../../assets/delete.svg"></td>
            </tr>
        </table>
    </div>
    <div class="news-outer-container" fxLayout="column" *ngFor="let category of newsCategories">
        <h1 class="title" style="margin-left: 1rem; margin-top: 1rem; font-size: 28px;">{{category?.name}}</h1>
        <table>
            <tr>
                <th>Titel</th>
                <th>Kategorie</th>
                <th>Erstellt am</th>
                <th></th>
                <th></th>
            </tr>
            <tr *ngFor="let news of getNewsArrayByCategoryName(category?.name)">
                <td style="font-weight: 500;">{{news?.title}}</td>
                <td>{{news?.category?.name}}</td>
                <td>{{formatDate(news?.date)}}</td>
                <td><img (click)="rerouteToUpdate(news)" class="icon" src="../../../assets/edit.svg"></td>
                <td (click)="deleteNews(news?.id)"><img class="icon" src="../../../assets/delete.svg"></td>
            </tr>
        </table>
    </div>
</section>
