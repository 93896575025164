import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-global-impressum',
    templateUrl: './global-impressum.component.html',
    styleUrls: ['./global-impressum.component.css']
})
export class GlobalImpressumComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
