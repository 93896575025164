<section id="login-navigation-container" fxLayout="row wrap" fxLayoutGap=".5rem">
    <div class="navigation-category" fxLayoutAlign="center center"
         [ngClass]="{active: loginNavigationService.selectedLoginNavigation === loginNavigationService.getSelectedLoginNavigationInstance().STARTSEITE}"
         (click)="loginNavigationService.selectNavigation(loginNavigationService.getSelectedLoginNavigationInstance().STARTSEITE)">Startseite</div>
    <div class="navigation-category" fxLayoutAlign="center center"
         [ngClass]="{active: loginNavigationService.selectedLoginNavigation === loginNavigationService.getSelectedLoginNavigationInstance().LOGIN}"
         (click)="loginNavigationService.selectNavigation(loginNavigationService.getSelectedLoginNavigationInstance().LOGIN)">Anmelden</div>
    <div class="navigation-category" fxLayoutAlign="center center"
         [ngClass]="{active: loginNavigationService.selectedLoginNavigation === loginNavigationService.getSelectedLoginNavigationInstance().IMPRESSUM}"
         (click)="loginNavigationService.selectNavigation(loginNavigationService.getSelectedLoginNavigationInstance().IMPRESSUM)">Impressum & Datenschutzerklärung</div>
</section>
