import {User} from './User';

export class CategoryDTO {
    public id: number;
    public name: string;
    public userName: string;
    public user: User;

    constructor(name: string, user: string) {
        this.name = name;
        this.userName = user;
    }
}
