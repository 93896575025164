import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-user-panel-impressum',
    templateUrl: './user-panel-impressum.component.html',
    styleUrls: ['./user-panel-impressum.component.css']
})
export class UserPanelImpressumComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
