import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {HeaderComponent} from './user-panel/header/header.component';
import {RouterModule, Routes} from '@angular/router';
import {FlexLayoutModule} from '@angular/flex-layout';
import {LoginComponent} from './login/login.component';
import {HttpClientModule} from '@angular/common/http';
import {UserPanelComponent} from './user-panel/user-panel.component';
import {LoginNavigationComponent} from './home/login-navigation/login-navigation.component';
import {UserPanelNavigationComponent} from './user-panel/user-panel-navigation/user-panel-navigation.component';
import {UserPanelCreateNewsComponent} from './user-panel/user-panel-create-news/user-panel-create-news.component';
import {UserPanelManageNewsComponent} from './user-panel/user-panel-manage-news/user-panel-manage-news.component';
import {GlobalImpressumComponent} from './global-impressum/global-impressum.component';
import {UserPanelImpressumComponent} from './user-panel/user-panel-impressum/user-panel-impressum.component';
import {BdmNewsOverviewComponent} from './bdm-news-overview/bdm-news-overview.component';
import {HomeHeaderComponent} from './home/home-header/home-header.component';
import {NgxImageCompressService} from 'ngx-image-compress';
import {UserPanelHomeComponent} from './user-panel/user-panel-home/user-panel-home.component';
import { FormsModule } from '@angular/forms';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'startseite',
        pathMatch: 'full'
    },
    {
        path: 'startseite',
        component: HomeComponent,
        data: {routeName: 'Startseite'}
    },
    {
        path: 'login',
        component: LoginComponent,
        data: {routeName: 'Login'}
    },
    {
        path: 'user-panel',
        component: UserPanelComponent,
        data: {routeName: 'Benutzeroberfläche'}
    },
    // {path: 'sportler-information-viewer/:id', component: SportlerInformationViewerComponent},
];

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        HeaderComponent,
        LoginComponent,
        UserPanelComponent,
        LoginNavigationComponent,
        UserPanelNavigationComponent,
        UserPanelCreateNewsComponent,
        UserPanelManageNewsComponent,
        GlobalImpressumComponent,
        UserPanelImpressumComponent,
        BdmNewsOverviewComponent,
        HomeHeaderComponent,
        UserPanelHomeComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        FlexLayoutModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {
            initialNavigation: 'enabled'
        }),
        FormsModule
    ],
    providers: [
        NgxImageCompressService,
        // Set max file size accepted in angular requests for auto generation of access header
        { provide: 'MAX_FILE_SIZE', useValue: 5000000 }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
