<section id="bdm-news-overview-container" fxLayout="column" fxLayoutGap="2rem">
    <div id="bdm-news-outer-container" fxLayoutAlign="start start" fxLayout="row wrap" fxLayoutGap="4rem">
        <div class="bdm-news" fxLayout="column" fxLayoutAlign="start center"
             fxLayoutGap=".5rem"
             *ngFor="let news of newsService.allNews">
            <img class="news-company-logo" src="{{news.userInformation.logoBase64}}">
            <p class="news-company-name"> {{news.userInformation.companyName}}</p>
<!--                  <div class="news-seperator"></div>-->
            <pre class="news-message">{{news.message}}</pre>
            <!--      <div class="news-seperator"></div>-->
            <p class="news-date">News vom {{formatDate(news.date)}}</p>
        </div>
    </div>
</section>
