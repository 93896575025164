import {Component, OnInit} from '@angular/core';
import {NewsService} from '../../services/news.service';
import {NewsDTO} from '../../models/NewsDTO';
import {UserService} from '../../login/services/user.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {UserPanelNavigationService} from '../services/user-panel-navigation.service';
import {UserEntity} from '../../login/entity/UserEntity';
import {CategoryDTO} from '../../models/CategoryDTO';
import {News} from '../../models/News';

@Component({
    selector: 'app-user-panel-manage-news',
    templateUrl: './user-panel-manage-news.component.html',
    styleUrls: ['./user-panel-manage-news.component.css']
})
export class UserPanelManageNewsComponent implements OnInit {

    public newsCategories: CategoryDTO[];

    public userNewsCategoryDataObject: NewsDTO[] = [];

    public newsWithoutCategory: NewsDTO[];

    constructor(private userService: UserService,
                private newsService: NewsService,
                private http: HttpClient,
                private nav: UserPanelNavigationService) {
    }

    ngOnInit() {
        this.initializeView();
    }

    initializeView() {
        this.userNewsCategoryDataObject = [];
        this.newsService.getAllNews().subscribe();
        this.userService.checkToken().toPromise()
            .then((user: UserEntity) => {
                this.userService.userEntity = user;
                this.userService.username = user.username;
                this.newsService.getNewsByAuthor(this.userService.username)
                    .toPromise()
                    .then((receivedNews: NewsDTO[]) => {
                        this.userNewsCategoryDataObject = receivedNews;
                        this.newsWithoutCategory = this.userNewsCategoryDataObject.filter((news: NewsDTO) => {
                            return !news.category;
                        });
                    });
                this.newsService.getCategories(this.userService.username)
                    .toPromise()
                    .then((receivedCategories: CategoryDTO[]) => {
                        this.newsCategories = receivedCategories;
                    });
            }).catch(() => {
                localStorage.removeItem('token');
            });
    }

    deleteNews(newsId: number) {
        this.http.post(environment._proxy + '/news/delete', {id: newsId} as News)
            .toPromise()
            .then(() => {
                this.initializeView();
            });
    }

    getNewsArrayByCategoryName(categoryName: string): NewsDTO[] {
        if (this.userNewsCategoryDataObject === undefined) {
            return [] as NewsDTO[];
        }
        return this.userNewsCategoryDataObject.filter(x => {
            if (!x.category) {
                return false;
            }
            return x.category?.name === categoryName;
        });
    }

    rerouteToUpdate(news: NewsDTO) {
        this.newsService.newsInWork = news;
        this.nav.selectNavigation(this.nav.getSelectedUserPanelNavigationInstance().ERSTELLEN);
    }

    formatDate(date: string): string {
        const d = new Date(date);
        return ('0' + d.getDate()).slice(-2) + '.'
            + ('0' + (d.getMonth() + 1)).slice(-2) + '.'
            + d.getFullYear();
    }
}
