import { Component, OnInit } from '@angular/core';
import {LoginNavigationService} from './login-navigation/services/login-navigation.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    constructor(public loginNavigationService: LoginNavigationService) { }

    ngOnInit() {
    }

}
